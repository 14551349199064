import LocalStorage from './LocalStorage.js';

class FetchModule {
  constructor(props){
    this.responseValue = [];
    this.baseURL = 'https://link-zone.org/';
    // this.baseURL = 'http://172.30.1.21:8080/';
    this.coachBaseURL = 'https://coach.link-zone.org/';
    // this.coachBaseURL = 'http://172.30.1.58:8080/';
    this.exerciseBaseURL = 'https://exercise.link-zone.org/'
    // this.exerciseBaseURL = 'http://172.30.1.53:8080/'
    this.eventBaseURL = 'https://tournament.link-coach.io/'
    // this.eventBaseURL = 'http://172.30.1.26:8080/';
    // this.eventBaseURL = 'http://localhost:8080/';
    this.apiVersion = 'api/';//'api/';
    this.apiVersion2 = 'api2/';
    // this.token = SessionStorage.getToken();
    // this.storeId = SessionStorage.getStoreId();
    this.token = LocalStorage.getToken();
    this.refreshToken = LocalStorage.getRefreshToken();
    this.storeId = LocalStorage.getStoreId();
  }

  postConfig(setMethod, requestData) {
    return {
      method: setMethod,
      headers: {
        'Authorization': window.localStorage.getItem('Token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    }
  }

  getConfig(setMethod) {
    return {
      method: setMethod,
      headers: {
        'Authorization': window.localStorage.getItem('Token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
  }

  // Token Refresh API //
  async refreshConfig() {
    // const token = await this.getToken();
    const accessToken = await window.localStorage.getItem('Token');
    const refreshToken = await window.localStorage.getItem('RefreshToken');

    var data = {
      accessToken: accessToken,
      refreshToken: refreshToken
    }

    return {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  }

  // Token Refresh API //
  async refreshAuthorization() {
    var config = await this.refreshConfig();
    var url = this.baseURL + this.apiVersion + 'account/refresh';

    const res = await fetch(url, config);
    const json_res = await res.json();
 
    if (json_res.status === 200) {
      await window.localStorage.setItem('Token', ('Bearer ' + json_res.token));
      if (json_res.refreshToken !== undefined) await window.localStorage.setItem('RefreshToken', ('Bearer ' + json_res.refreshToken));
    }
  }

  async deleteData(middleURL, setMethod) {
    var config = this.getConfig(setMethod);
    var url = this.baseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.getConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async patchData(middleURL, setMethod, requestData) {
    var config = this.postConfig(setMethod, requestData);
    var url = this.baseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }
    
    return json_res;
  }
 
  async postData(middleURL, setMethod, requestData) {
    var config = this.postConfig(setMethod, requestData);
    var url = this.baseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getData(middleURL, setMethod) {
    var config = this.getConfig(setMethod)
    var url = this.baseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }
    
    return json_res;
  }

  async postDataOfCoach(middleURL, setMethod, requestData) {
    var config = this.postConfig(setMethod, requestData);
    var url = this.coachBaseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getDataOfCoach(middleURL, setMethod) {
    var config = this.getConfig(setMethod)
    var url = this.coachBaseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  // Exercise //
  async postDataOfExercise(middleURL, setMethod, requestData) {
    var config = this.postConfig(setMethod, requestData);
    var url = this.exerciseBaseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getDataOfExercise(middleURL, setMethod) {
    var config = this.getConfig(setMethod)
    var url = this.exerciseBaseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Exercise //

  // Event //
  async postDataOfEvent(middleURL, setMethod, requestData) {
    var config = this.postConfig(setMethod, requestData);
    var url = this.eventBaseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getDataOfEvent(middleURL, setMethod) {
    var config = this.getConfig(setMethod)
    var url = this.eventBaseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Event //

  // Zone API 2 //
  async getDataOfZoneVer2(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.baseURL + this.apiVersion2 + middleURL;
    console.log('url : ' + url);
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.getConfig(setMethod);
      
      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async postDataOfZoneVer2(middleURL, setMethod, requestData) {
    let config = await this.postConfig(setMethod, requestData);
    let url = this.baseURL + this.apiVersion2 + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod, requestData);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Zone API 2 //

  // Event Version2 //
  async postDataOfEventVer2(middleURL, setMethod, requestData) {
    var config = this.postConfig(setMethod, requestData);
    var url = this.eventBaseURL + this.apiVersion2 + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getDataOfEventVer2(middleURL, setMethod) {
    var config = this.getConfig(setMethod)
    var url = this.eventBaseURL + this.apiVersion2 + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      var newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Event Version2 //
}

export default FetchModule;
