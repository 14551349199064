import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import DownIcon from '../../Image/DownIcon.png';
import GrayExit from '../../Image/GrayExit.png';
import PlusIcon from '../../Image/PlusIcon.png';
// import LoadingAnimation from '../Model/EventApplicationModel/LoadingAnimation';

import AdditionalSouvenirModal from '../../Model/EventSelectModel/AdditionalSouvenirModal';
import CommonSouvenirModal from '../../Model/EventSelectModel/CommonSouvenirModal';
import EventSelectModal from '../../Model/EventSelectModel/EventSelectModal';
import FetchModule from '../../Model/Network/FetchModule';

export default function UrbanfitInvitationalSelectPage() {

  const navigate = useNavigate();
  // const params = useParams();

  const [data, setData] = useState({
    title: '',
    commonSouvenir: {title: ''},
    additionalSouvenirs: []
  });
  const [selectedEvent, setSelectedEvent] = useState({eventId: 0, eventTitle: t('종목을 선택해주세요'), isTeamFight: false});
  const [teamNumber, setTeamNumber] = useState(4);
  const [isClickedSelectEvent, setIsClickedSelectEvent] = useState(false);
  const [isClickedCommonSouvenirSetting, setIsClickedCommonSouvenirSetting] = useState(false);
  const [isClickedAdditionalSouvenirSetting, setIsClickedAdditionalSouvenirSetting] = useState(false);
  const [isClickedAdditionalIndex, setIsClickedAdditionalIndex] = useState(-1);
  const [commonSouvenirCount, setCommonSouvenirCount] = useState(0);
  const [additionalSouvenirList, setAdditionalSouvenirList] = useState([]);
  const [totalString, setTotalString] = useState('');
  const [newTotalString, setNewTotalString] = useState([]);
  const [commonPrice, setCommonPrice] = useState(0);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const [totalParticipationFee, setTotalParticipationFee] = useState(0);
  const [vat, setVat] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [eventDay, setEventDay] = useState('A');

  const [isLoading, setIsLoading] = useState(false);

  const organizerId = 31;

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('종목 선택 하기'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // 대회 참가 종목 및 기념품 데이터 조회 //
  useEffect(() => {
    let requestData = {
      organizerId: organizerId
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('tournament/detail', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          let data = responseData.data;
          let eventData = responseData.data.events;

          eventData.sort(function(a, b) {
            let x = a.eventId;
            let y = b.eventId;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          // let newEvent = JSON.parse(JSON.stringify(eventData));

          // for (let i = 0; i < newEvent.length; i++) {
          //   eventData.push(newEvent[i]);
          // }

          // console.log(eventData);
          data.events = eventData;

          setData(data);
				}
      }
    )();
  }, [])

  useEffect(() => {
    let newData = JSON.parse(window.localStorage.getItem('EventData'));

    if (newData === null) return;

    setSelectedEvent(newData.selectedEvent);
    setCommonSouvenirCount(newData.commonSouvenirCount);
    setAdditionalSouvenirList(newData.additionalSouvenirList);
    setTeamNumber(newData.teamNumber);
    setTotalString(newData.totalString);
    setCommonPrice(newData.commonPrice);
    setAdditionalPrice(newData.additionalPrice);
    setTotalPrice(newData.totalPrice);
    setVat(newData.vat);
  }, [])

  // 기념품 가격 측정 //
  useEffect(() => {
    let totalString = '';
    let commonPrice = 0;
    let additionalTotalPrice = 0;

    if (commonSouvenirCount !== 0) {
      totalString += data.commonSouvenir.title + ' x ' + commonSouvenirCount;
      commonPrice += data.commonSouvenir.price * commonSouvenirCount;
    }

    for (let i = 0; i < additionalSouvenirList.length; i++) {
      if (totalString !== '') totalString += ',';

      totalString += 
      ( ' ' + additionalSouvenirList[i].title 
        + ' ' + additionalSouvenirList[i].gender 
        + ' ' + additionalSouvenirList[i].size
        + ' ' + (additionalSouvenirList[i].count === 0 ? '' : ' x ' + additionalSouvenirList[i].count)); 
    
      for (let j = 0; j < data.additionalSouvenirs.length; j++) {
        if (additionalSouvenirList[i].id === data.additionalSouvenirs[j].additionalSouvenirId) {
          additionalTotalPrice += data.additionalSouvenirs[j].price * additionalSouvenirList[i].count;
          break;
        }
      }
    }
    
    setCommonPrice(commonPrice);
    setAdditionalPrice(additionalTotalPrice);
    setTotalString(totalString);
    setNewTotalString(totalString.split(','))
  }, [data, commonSouvenirCount, additionalSouvenirList])

  // 최종 가격 산정 //
  useEffect(() => {
    // let participationFee = 0;
    
    // if (selectedEvent.isTeamFight) participationFee = selectedEvent.participationFee === undefined ? 0 : selectedEvent.participationFee * teamNumber;
    // else participationFee = selectedEvent.participationFee === undefined ? 0 : selectedEvent.participationFee;
    
    let participationFee = selectedEvent.participationFee === undefined ? 0 : selectedEvent.participationFee;
    let vat = (participationFee + commonPrice + additionalPrice) * 0.1;
    
    setVat(vat);
    setTotalParticipationFee(participationFee);
    // setTotalPrice(participationFee + commonPrice + additionalPrice + vat);
    setTotalPrice(participationFee + commonPrice + additionalPrice);
  }, [selectedEvent, commonPrice, additionalPrice, teamNumber])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
  }

  // 종목 선택 클릭시 //
  function onClickSelectEvent() {
    setIsClickedSelectEvent(true);
  }

  // 공통 기념품 선택 클릭시 //
  function onClickSelectCommonSouvenir() {
    setIsClickedCommonSouvenirSetting(true);
  }

  // 추가 기념품 선택 클릭시 //
  function onClickSelectAdditionalSouvenir(index) {
    setIsClickedAdditionalIndex(index);
    setIsClickedAdditionalSouvenirSetting(true);
  }

  // 공통 기념품 삭제 버튼 클릭시 //
  function onClickDeleteCommonSouvenir() {
    setCommonSouvenirCount(0);
  }

  // 추가 기념품 삭제 버튼 클릭시 //
  function onClickDeleteAdditionalSouvenir(index) {
    let newList = additionalSouvenirList.slice();

    newList.splice(index, 1);
    setAdditionalSouvenirList(newList);
  }

  // 팀원 수 더하기 클릭시 //
  function onClickPlusButton() {
    alert('4인 1팀 구성입니다');
    // if (teamNumber < 6) setTeamNumber(teamNumber + 1);
  }

  // 팀원 수 빼기 클릭시 //
  function onClickMinusButton() {
    alert('4인 1팀 구성입니다');
    // if (teamNumber > 4) setTeamNumber(teamNumber - 1);
  }

  // 이전 페이지로 이동 클릭시 //
  function onClickPrevButton() {
    navigate(-1);
  }

  // 다음 페이지로 이동 클릭시 //
  function onClickNextButton() {
    setIsLoading(true);
    
    if (selectedEvent.eventTitle === t('종목을 선택해주세요')) {
      alert(t('종목을 선택해주세요'));
      return;
    }
    else if (selectedEvent.isSoldOut) {
      alert(selectedEvent.eventTitle + '\n참가 인원이 마감되었습니다.');
      return;
    }

    let requestData = {
      eventId: selectedEvent.eventId
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('tournament/check', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          
          if (responseData.data) alert('이미 신청내역이 존재합니다!');
          else {
            let newData = {
              title: data.title,
              organizerId: data.organizerId,
              selectedEvent: selectedEvent,
              teamNumber: 2,
              commonSouvenirCount: commonSouvenirCount,
              commonSouvenir: data.commonSouvenir,
              additionalSouvenirs: data.additionalSouvenirs,
              additionalSouvenirList: additionalSouvenirList,
              totalString: totalString,
              commonPrice: commonPrice,
              additionalPrice: additionalPrice,
              totalPrice: totalPrice,
              vat: vat,
              // eventDay: eventDay
            }
        
            window.localStorage.setItem('EventData', JSON.stringify(newData));
            
            navigate('/application/urbanfit_invitational/' + selectedEvent.eventId);
          }
        }
      }
    )();

    setIsLoading(false);
  }

  return (
    <TopLevelWrapper>
      { 
        isClickedSelectEvent && 
        <EventSelectModal 
          data={data} 
          setSelectedEvent={setSelectedEvent} 
          closeFn={() => setIsClickedSelectEvent(false)}/> 
      } 
      { 
        isClickedCommonSouvenirSetting && 
        <CommonSouvenirModal 
          data={data.commonSouvenir} 
          commonSouvenirCount={commonSouvenirCount} 
          setCommonSouvenirCount={setCommonSouvenirCount} 
          closeFn={() => setIsClickedCommonSouvenirSetting(false)}/> 
      }
      { 
        isClickedAdditionalSouvenirSetting && 
        <AdditionalSouvenirModal 
          data={data.additionalSouvenirs[isClickedAdditionalIndex]} 
          index={isClickedAdditionalIndex}
          souvenirList={additionalSouvenirList} 
          setSouvenirList={setAdditionalSouvenirList}
          closeFn={() => setIsClickedAdditionalSouvenirSetting(false)}/> 
      }
      <ComponentWrapper>
        <TitleWrapper style={{ marginTop: '22px' }}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>참가하실 이벤트를 선택해주세요</Text>
        </TitleWrapper>
        <SelectBoxWrapper style={{ marginTop: '22px' }}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('종목 선택')}</Text>
          <SelectBox onClick={onClickSelectEvent}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#333333'>{selectedEvent.eventTitle}</Text>
            <Image src={DownIcon} />
          </SelectBox>
          {/* <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Light' fontSize={12} color='#FF3131'>{t('동일한 종목을 중복으로 선택할 수 없습니다')}</Text> */}
        </SelectBoxWrapper>
        {
          selectedEvent.eventId === 60 && 
          <SelectBoxWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>*Scaled 디비전 중</Text>
            <Text style={{ marginTop: `4px` }} fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>1. 마스터즈 : 두 명 모두 40세 이상인 팀 (40세 이상 : 1984.12.31 이전 출생자)</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>2. 루키 : 두 명 모두 20세 미만인 팀 (20세 미만 : 2005.1.1 이후 출생자)</Text>
            <TextWrapper style={{ marginTop: `4px`, gap: '4px' }} >
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>은 팀명 뒤에</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#2F80ED'>"M"</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>(마스터즈), 혹은</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#FF8B48'>"R"</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>(루키)를 붙여 신청주시면 따로 순위 집계하여 상품이 제공 될 예정입니다.</Text>
            </TextWrapper>
            <TextWrapper style={{ marginTop: `4px`, gap: '4px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>예)</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#2F80ED'>팀 얼번핏M</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>/</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#FF8B48'>팀 얼번핏R</Text>
            </TextWrapper>
          </SelectBoxWrapper>
        }
        <DivisionLine style={{ marginTop: `18px`}}/>
        {/* {
          selectedEvent.isTeamFight &&
          <SelectBoxWrapper style={{ marginTop: '16px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('팀 인원')}</Text>
            <InputWrapper style={{ marginTop: '8px' }}>
              <Input style={{ marginRight: '6px' }}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{teamNumber}</Text>
              </Input>
              <IncreaseButton style={{ marginRight: '4px' }} onClick={onClickMinusButton}>
                <div style={{ width: `20px`, height: `3px`, borderRadius: `12px`, backgroundColor: '#FFFFFF' }} />
              </IncreaseButton>
              <IncreaseButton style={{ marginRight: '4px' }} onClick={onClickPlusButton}>
                <Image src={PlusIcon} />
              </IncreaseButton>
            </InputWrapper>
            <ExplanationWrapper>
              <Text style={{ marginRight: '3px' }} fontFamily='NotoSansKR-Light' fontSize={12} color='#333333'>{t('최대 팀원 수는')}</Text>
              <Text style={{ marginRight: '3px' }} fontFamily='NotoSansKR-Light' fontSize={12} color='#FF3131'>4명</Text>
              <Text fontFamily='NotoSansKR-Light' fontSize={12} color='#333333'>{t('입니다')}</Text>
            </ExplanationWrapper>
          </SelectBoxWrapper>
        } */}
        {/* {
          data.commonSouvenir !== undefined &&
          <AdditionalWrapper>
            <TitleWrapper style={{ marginTop: '24px' }}>
              <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#4F4F4F'>{t('공통 기념품 선택')}</Text>
            </TitleWrapper>
            <SelectBoxWrapper style={{ marginTop: '16px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{data.commonSouvenir.title}</Text>
              <SelectBox onClick={onClickSelectCommonSouvenir}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#333333'>{t('선택하기')}</Text>
                <Image src={DownIcon} />
              </SelectBox>
            </SelectBoxWrapper>
          </AdditionalWrapper>
        } */}

        <TitleWrapper style={{ marginTop: '34px' }}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('추가 기념품 선택')}</Text>
        </TitleWrapper>
        {
          commonSouvenirCount !== 0 &&
          <ItemsList>
            <ItemWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- {data.commonSouvenir.title}{commonSouvenirCount > 1 ? ' X ' + commonSouvenirCount : commonSouvenirCount}</Text>
              <Image src={GrayExit} onClick={onClickDeleteCommonSouvenir}/>
            </ItemWrapper>
          </ItemsList>
        }
        {
          data.additionalSouvenirs.map((data, index) => (
            <AdditionalWrapper key={index}>
              {/* <TitleWrapper style={{ marginTop: '24px' }}>
                <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#4F4F4F'>{t('추가 기념품 선택') + ' ' + String.fromCharCode(index + 65)}</Text>
              </TitleWrapper> */}
              <SelectBoxWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>
                  {data.title === '2025 얼번핏 인비테이셔널 - 크롭티' ? '2025 얼번핏 인비테이셔널 - 크롭티 29,000원' : '2025 얼번핏 인비테이셔널 - 티셔츠 25,000원'}
                </Text>
              </SelectBoxWrapper>
              <SouvenirImage style={{ marginTop: '10px' }} src={data.additionalSouvenirId === 16 ? 'https://picture.link-zone.org/event/2025_URBANFIT_INVITATIONAL/urbanfit_invitational_tshirts.png' : 'https://picture.link-zone.org/event/2025_URBANFIT_INVITATIONAL/urbanfit_invitational_crop.png'} />
              <SelectBoxWrapper style={{ marginTop: '16px' }}>
                {/* <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{data.title}</Text> */}
                <SelectBox onClick={() => onClickSelectAdditionalSouvenir(index)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#333333'>{t('선택하기')}</Text>
                  <Image src={DownIcon} />
                </SelectBox>
              </SelectBoxWrapper>
              {
                additionalSouvenirList.map((souvenirData, index) => (
                  souvenirData.id === data.additionalSouvenirId &&
                  <ItemsList key={index}>
                    <ItemWrapper>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>
                        - {souvenirData.title + ' ' + (souvenirData.gender === undefined ? '' : souvenirData.gender) + ' ' + (souvenirData.size === undefined ? '' : souvenirData.size) + (souvenirData.count === 0 ? '' : ' x ' + souvenirData.count)}
                      </Text>
                      <Image src={GrayExit} onClick={() => onClickDeleteAdditionalSouvenir(index)}/>
                    </ItemWrapper>
                  </ItemsList>
                ))
              }

            <DivisionLine style={{ marginTop: `18px`}}/>
            </AdditionalWrapper>
          ))
        }
        <DivisionBigLine/>
        <ItemTotalListWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{selectedEvent.eventTitle}</Text>
          <ItemTotalList>
            {
              newTotalString.map((data, index) => (
                <Text style={{ marginTop: '6px' }} key={index} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>
                  - {data}
                </Text>
              ))
            }
          </ItemTotalList>
        </ItemTotalListWrapper>
        <DivisionLine style={{ marginTop: `36px`}}/>
        <PriceBoxWrapper>
          <PriceLineWrapper style={{ marginTop: `0px`}}>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{selectedEvent.isTeamFight ? t('참가비') + '( ' + teamNumber + t('인') + ' )' : t('참가비')}</Text> */}
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('참가비')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{totalParticipationFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + t('원')}</Text>
          </PriceLineWrapper>
          {/* <PriceLineWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('공통 기념품')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{commonPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + t('원')}</Text>
          </PriceLineWrapper> */}
          <PriceLineWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('추가 기념품')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{additionalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + t('원')}</Text>
          </PriceLineWrapper>
          {/* <PriceLineWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('부가세')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{vat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + t('원')}</Text>
          </PriceLineWrapper> */}
        </PriceBoxWrapper>
        <DivisionLine/>
        <PriceBoxWrapper>
          <PriceLineWrapper style={{ marginTop: `0px`}}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('총 주문 금액')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + t('원')}</Text>
          </PriceLineWrapper>
        </PriceBoxWrapper>
      </ComponentWrapper>
        <NextButtonWrapper style={{ marginTop: '12px' }}>
          {/* <NextInnerButtonWrapper>
            <Button backgroundColor='#E0E0E0' onClick={onClickPrevButton}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('이전')}</Text>
            </Button>
            <Button backgroundColor='#6DD49E' onClick={onClickNextButton}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('다음')}</Text>
            </Button>
          </NextInnerButtonWrapper> */}
          <NewButton onClick={onClickNextButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('다음')}</Text>
          </NewButton>
        </NextButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  // height: 100%;
  height: 100vh;

  background-color: #F9F9F9;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const AdditionalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 14px 0px 14px;
  margin: 8px 0px 0px 0px;

  width: calc(100% - 30px);
  height: 49px;

  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #FFFFFF;

  :hover {
    cursor: default;
  }
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const DivisionBigLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 36px 0px 36px 0px;

  width: 100%;
  height: 3px;

  background-color: #E0E0E0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 18px 0px 18px 0px;

  width: 90.33%;
  height: 1px;

  background-color: #E0E0E0;
`;

const ItemTotalListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ItemTotalList = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // margin-top: 12px;
  margin-top: 4px;

  width: 100%;
`;

const PriceBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const PriceLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  width: 90.33%;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-end;

  // padding: 0px 0px 34px 0px;
  // margin: 52px 0px 0px 0px;

  width: 100%;
  // height: 68px;

  // background-color: #FFFFFF;
`;

const NextInnerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 60px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const NewButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #6DD49E;
`;

const SouvenirImage = styled.img`
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;